/* Add more @import rules for other fonts as needed */
/* //! font-family starts here  */

@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

.unbounded-font {
  font-family: 'Unbounded', sans-serif;
}

/* //! font-family ends here  */
/* navbar css */
ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

li {
  position: relative;
  display: block;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.1rem;
}

li::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
  background-color: #f4c708;
}

li.mx-3.font-bold.cursor-pointer.text-black.bg-\[\#FFD700\].h-10.max-h-\[40px\].w-10.max-w-\[40px\].rounded-lg.text-center.align-middle.active {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

li:hover {
  color: #fff;
}

li:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* //! hamburger menu  */
.hamburger-icon {
  font-size: 2rem;
  color: #fff;
}

.parent-burger-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
}

.parent-navlink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  width: 200px;
  /* border: 1px solid red */
}

.parent-logo-one {
  display: flex;
  align-items: center;
}

.hamburger-title {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-size: 1.6rem;
  color: #002242;
  font-family: 'proxima-nova', sans-serif;
  padding: 25px 16px;
  overflow-y: scroll;
  width: 100%;
  line-height: 29px;
  font-weight: 800;
}

.hamburger-title:hover {
  border-bottom: 3px solid #002242;
}

.parent-burger {
  display: flex;
  justify-content: center;
}

/* //! swaper start */
.swiper {
  width: 100%;
  box-shadow: 0px 0px 12px 0px #FFD700;
  border-radius: 8px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

/* //! swaper end */

/* //! header carousel css here */
.swiper-button-prev {
  color: white !important;
}

.swiper-button-next {
  color: white !important;
}

.swiper-button-prev:after {
  display: none;
}

.swiper-button-next:after {
  display: none;
}

/* //! header carousel css here end */

.parent-movie-menu {
  background-color: #FFD700;
}

.movie-sum {
  text-shadow: 0px 0px 4px rgba(255, 0, 0);
}

/* //! scroll bar css here */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(219, 219, 219)
}

::-webkit-scrollbar-thumb {
  /* background-color: #FACC15; */
  background-color: #FFD700;
  border-radius: 16px;
}

/* //! scroll bar css end here */

/* //! header info */
.header_info {
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  z-index: 1;
}

.header {
  color: #EBE3D5;
  font-size: 3rem;
  font-weight: 800;
  font-family: 'Unbounded', sans-serif;
}

@media (min-width: 1px) and (max-width: 426px) {
  .header_info {
    bottom: 1rem;
    left: 2.8rem;
  }

  .header {
    color: white;
    font-size: 1rem;
    font-weight: 800;
    font-family: 'Unbounded', sans-serif;
  }
}

input::placeholder {
  color: black;
  font-weight: bold;

}

/*loader*/
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: not-allowed;
  scale: 0.8;
}

.central {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 0.5em 1em 1em blue,
    -0.5em 0.5em 1em lime,
    0.5em -0.5em 1em cyan,
    -0.5em -0.5em 1em yellow;
}

.external-shadow {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0.5em 0.5em 3em blue,
    -0.5em 0.5em 3em lime,
    0.5em -0.5em 3em cyan,
    -0.5em -0.5em 3em yellow;
  z-index: 999;
  animation: rotate 3s linear infinite;
  background-color: #212121;
}

.intern {
  position: absolute;
  color: white;
  z-index: 9999;
}

.intern::before {
  content: "100%";
  animation: percent 2s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes percent {
  0% {
    content: '0%';
  }

  25% {
    content: '25%';
  }

  33% {
    content: '33%';
  }

  42% {
    content: '42%';
  }

  51% {
    content: '51%';
  }

  67% {
    content: '67%';
  }

  74% {
    content: '74%';
  }

  75% {
    content: '75%';
  }

  86% {
    content: '86%';
  }

  95% {
    content: '95%';
  }

  98% {
    content: '98%';
  }

  99% {
    content: '99%';
  }
}

/* //! comming-soon css */
.soon-image {
  border-radius: 10px;
}