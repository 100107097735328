@keyframes slider {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.parent-card-carousel {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(204deg, rgb(0, 0, 0) 30.79%, rgb(194, 157, 0) 80.6%); */
    ;
}

.logos {
    white-space: nowrap;
    overflow: hidden;
    background-color: transparent;
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 10s slider infinite linear;
}

.parent-product {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 1rem;
}

.product-header {
    position: absolute;
    bottom: 1.2rem;
    left: 0.4rem;
    padding: 5px;
    color: white;
    font-size: 1.2rem;
    font-weight: 800;
    /* z-index: 10; */
}

.project-card {
    margin: 0px;
    padding: 0px;
}

.parent-product .project-card:hover {
    scale: 1.1;
    transition: 0.6s ease-in;
    filter: blur(3px);
    cursor: pointer;
}

.logos-slide img {
    width: 380px;
}